import React, { useState } from 'react';
import { Button } from '@/components/atoms/Button';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import { MONTH_OPTIONS, STATUS_OPTIONS, KPI_DATA, CENTRES_DATA } from '@/pages/dashboard/constants';
import { FarmersProfilesTable } from "@/components/molecules/FarmersProfilesTable";
import { CollectionProvider } from '@/contexts/CollectionContext';
import { FilterControls } from '@/components/molecules/FilterControls';
import { Sidebar } from '@/components/organisms/Sidebar';

export default function FarmersProfile() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [status, setStatus] = useState('');
  const navigate = useNavigate(); // Get the navigate function

  const monthOptions = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    // ... add all months
  ];

  const statusOptions = [
    { value: 'all', label: 'All Status' },
    { value: 'completed', label: 'Completed' },
    { value: 'in_progress', label: 'In Progress' },
    { value: 'delayed', label: 'Delayed' },
  ];

  // Click handler to redirect to the add new driver page
  const handleAddNewFarmer = () => {
    navigate('/farmer/add-new-farmer'); // Redirect to the specified route
  };

  return (
    <CollectionProvider>
      <div className="flex h-screen bg-gray-100">
        <Sidebar />
        <div className="flex-1 flex flex-col overflow-hidden">
          <header className="bg-white shadow">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
              <h1 className="text-3xl font-bold text-gray-900">Farmers Profiles</h1>
              <div className="mt-4 flex justify-between items-center">
                  <div className="space-x-2">
                    <Button variant="secondary">Upload</Button>
                    <Button className="px-2 py-2 mx-1 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300" onClick={handleAddNewFarmer}>Add New Farmer</Button>
                  </div>
                </div>
            </div>
          </header>
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              <div className="px-4 py-6 sm:px-0">
                <div className='pb-4'>
                  <FilterControls
                    monthOptions={monthOptions}
                    statusOptions={statusOptions}
                    selectedMonth={selectedMonth}
                    status={status}
                    onMonthChange={setSelectedMonth}
                    onStatusChange={setStatus}
                    searchTerm={searchTerm}
                    onSearchChange={(e) => setSearchTerm(e.target.value)}
                    selectedDate={selectedDate}
                    onDateChange={(e) => setSelectedDate(e.target.value)}
                  />
                </div>
                <FarmersProfilesTable
                  selectedMonth={selectedMonth}
                  status={status}
                  onMonthChange={setSelectedMonth}
                  onStatusChange={setStatus}
                  monthOptions={MONTH_OPTIONS}
                  statusOptions={STATUS_OPTIONS}
                />
              </div>
            </div>
          </main>
        </div>
      </div>
    </CollectionProvider>
  );
}
