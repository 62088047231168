// src/api/services/base.service.ts
import api from '../axios';
import { BaseModel } from '../../models/common.types';

export interface CrudOperations<T extends BaseModel, C = Omit<T, keyof BaseModel>, U = Partial<C>> {
  getAll(): Promise<T[]>;
  getById(id: string): Promise<T>;
  create(data: C): Promise<T>;
  update(id: string, data: U): Promise<T>;
  delete(id: string): Promise<void>;
}

export abstract class BaseService<T extends BaseModel, CreateDTO = Omit<T, keyof BaseModel>, UpdateDTO = Partial<CreateDTO>> implements CrudOperations<T, CreateDTO, UpdateDTO> {
  protected constructor(protected endpoint: string) {}

  async getAll(): Promise<T[]> {
    const response = await api.get<T[]>(this.endpoint);
    return response.data;
  }

  async getById(id: string): Promise<T> {
    const response = await api.get<T>(`${this.endpoint}/${id}`);
    return response.data;
  }

  async create(data: CreateDTO): Promise<T> {
    const response = await api.post<T>(this.endpoint, data);
    return response.data;
  }

  async update(id: string, data: UpdateDTO): Promise<T> {
    const response = await api.put<T>(`${this.endpoint}/${id}`, data);
    return response.data;
  }

  async delete(id: string): Promise<void> {
    await api.delete(`${this.endpoint}/${id}`);
  }
}