// src/api/services/user-role.service.ts
import { Role, Route } from '@/models';
import { UserRole, UserRoleCreate, UserRoleUpdate } from '../../models/user-role.types';
import api from '../axios';
import { BaseService } from './base.service';

class UserRoleService extends BaseService<UserRole, UserRoleCreate, UserRoleUpdate> {
  constructor() {
    super('/user-roles');
  }

  async getUserRolesRoles(params: { user_id: string; org_id: string }) {
    const response = await api.get<Role[]>(`${this.endpoint}/roles`, { params });
    return response.data;
  }

  async getUserRolesRoutes(params: { user_id: string; org_id: string; role_id: string }) {
    const response = await api.get<Route[]>(`${this.endpoint}/routes`, { params });
    return response.data;
  }

  // Updated method to fetch user roles with dynamic population and filtering
  async fetchUserRoles(orgId: string, populate: string, filter: string) {
    const response = await api.get<UserRole[]>(`${this.endpoint}?populate=${populate}&org_id=${orgId}&filter=${filter}`);
    return response.data;
  }
}

export default new UserRoleService();
