import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@/components/atoms/Card';
import reportsService from '@/api/services/reports.service'
import { RoutesAndCollectionCentersReport } from '@/models/report.types';
import GenericTable from '@/components/atoms/GenericTable';
import { useAuth } from '@/contexts/AuthContext';

interface CollectionCentersTableProps {
  orgId: string; // Add orgId as a prop
  populate: string; // Add populate as a prop
  filter: string; // Add filter as a prop
  selectedMonth: string;
  status: string;
  onMonthChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  monthOptions: Array<{ value: string; label: string }>;
  statusOptions: Array<{ value: string; label: string }>;
  className?: string;
  'data-testid'?: string;
}

export const CollectionCentersTable: React.FC<CollectionCentersTableProps> = ({
  selectedMonth,
  status,
  onMonthChange,
  onStatusChange,
  monthOptions,
  statusOptions,
  className,
  'data-testid': testId = 'centres-table',
  ...props
}) => {
  const [collectionCenters, setCollectionCenters] = useState<RoutesAndCollectionCentersReport[]>([]); // State to hold collectionCenters data
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    const fetchLocationsData = async () => {
      try {
        const populate = ''; // Specify the fields to populate
        const filter = ''; // Specify the filter
        const orgId = user?.org_id
        const fetchedLocations = await reportsService.getRoutesAndCollectionCentersReports(orgId); // Fetch collectionCenters
        setCollectionCenters(fetchedLocations);
      } catch (err) {
        setError('Failed to fetch collectionCenters');
      } finally {
        setLoading(false);
      }
    };

    fetchLocationsData();
  }, []); // Dependencies for useEffect

  if (loading) return <div>Loading collectionCenters...</div>;
  if (error) return <div>{error}</div>;

  const columns = [
    { header: 'Collection Center', accessor: 'route_name', sortable: true },
    { header: 'Location', accessor: 'address', sortable: true }, //missing
    { header: 'Assigned QA Agent', accessor: 'scc', sortable: true }, // instead of driver should be QA agent
    { header: 'Farmers', accessor: 'location', sortable: true }, // missing
    // { header: 'Total Milk Collected', sortable: true },
    // { header: 'Rejected Milk', sortable: true },
  ];

  const handleActionClick = (actionType: string, item: any) => {
    console.log(`Action ${actionType} clicked for:`, item);
    // Implement your action logic here
  };

  const renderRowActions = (item: any) => {
    const actions = [
      { type: 'Edit', label: 'Edit', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-4 mx-2 rounded' },
    ];

    if (actions.length === 0) {
      return null;
    }

    return (
      <>
        {actions.map(action => (
          <button
            key={action.type}
            onClick={() => handleActionClick(action.type, item)}
            className={action.className} // Apply Tailwind CSS classes
          >
            {action.label}
          </button>
        ))}
      </>
    );
  };

  return (
    <Card className={className} data-testid={testId} {...props}>
      <CardContent className="p-4">
        <GenericTable
          data={collectionCenters} // Use fetched user roles data
          columns={columns}
          testId={`${testId}-table`}
          renderRowActions={renderRowActions}
        />
      </CardContent>
    </Card>
  );
};
