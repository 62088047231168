import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@/components/atoms/Card';
import reportsService from '@/api/services/reports.service'
import { UserRole } from '@/models/user-role.types'; // Import the UserRole type
import GenericTable from '@/components/atoms/GenericTable';
import { useAuth } from '@/contexts/AuthContext';
import { formatDate } from '@/utils/date.utils'; // Import the formatDate function

interface DriverTrackingTableProps {
  selectedMonth: string;
  status: string;
  onMonthChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  monthOptions: Array<{ value: string; label: string }>;
  statusOptions: Array<{ value: string; label: string }>;
  className?: string;
  'data-testid'?: string;
}

export const DriverTrackingTable: React.FC<DriverTrackingTableProps> = ({
  selectedMonth,
  status,
  onMonthChange,
  onStatusChange,
  monthOptions,
  statusOptions,
  className,
  'data-testid': testId = 'centres-table',
  ...props
}) => {
  const [userRoles, setUserRoles] = useState<UserRole[]>([]); // State to hold user roles
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    const fetchDriverRoles = async () => {
      try {
        const populate = ''; // Specify the fields to populate
        const filter = ''; // Specify the filter
        const orgId = user?.org_id
        const roles = await reportsService.getDriverTrackingReports(orgId)
        setUserRoles(roles);
      } catch (err) {
        setError('Failed to fetch user roles');
      } finally {
        setLoading(false);
      }
    };

    fetchDriverRoles();
  }, []);

  if (loading) return <div>Loading user roles...</div>;
  if (error) return <div>{error}</div>;

  const columns = [
    {
      header: 'Driver',
      accessor: 'driver_name',
      sortable: true,
      render: (item) => (
        <div className="flex items-center">
          {/* <img src={item.imageUrl} alt={item.data_source} className="w-8 h-8 rounded-full mr-2" /> */}
          <img src="https://picsum.photos/200/300" alt={item.data_source} className="w-8 h-8 rounded-full mr-2" />
          {item.driver_name}
        </div>
      ),
    },
    { header: 'Current Route', accessor: 'current_route', sortable: true},
    { header: 'Status', accessor: 'status', sortable: true },
    { header: 'Last Update', accessor: 'last_update', sortable: true, render: (item) => formatDate(item.last_update) },
    { header: 'Next Collection Center', accessor: 'next_collection_center', sortable: true },
    { header: 'Milk Cans Collected', accessor: 'milk_cans_collected', sortable: true },
  ];

  const handleActionClick = (actionType: string, item: any) => {
    console.log(`Action ${actionType} clicked for:`, item);
    // Implement your action logic here
  };

  const renderRowActions = (item: any) => {
    const actions = [
      { type: 'Message', label: 'Message', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-1 px-1 mx-1 rounded' },
      { type: 'Call', label: 'Call', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-1 px-1 mx-1 rounded' },
      { type: 'View Progress', label: 'View Progress', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-1 px-1 mx-1 rounded' },
    ];

    if (actions.length === 0) {
      return null;
    }

    return (
      <>
        {actions.map(action => (
          <button
            key={action.type}
            onClick={() => handleActionClick(action.type, item)}
            className={action.className} // Apply Tailwind CSS classes
          >
            {action.label}
          </button>
        ))}
      </>
    );
  };

  return (
    <Card className={className} data-testid={testId} {...props}>
      <CardContent className="p-4">
        <GenericTable
          data={userRoles} // Use fetched user roles data
          columns={columns}
          testId={`${testId}-table`}
          renderRowActions={renderRowActions}
        />
      </CardContent>
    </Card>
  );
};
