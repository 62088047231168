// src/api/services/permission.service.ts
import { Permission, PermissionCreate, PermissionUpdate } from '../../models/permission.types';
import api from '../axios';
import { BaseService } from './base.service';

class PermissionService extends BaseService<Permission, PermissionCreate, PermissionUpdate> {
  constructor() {
    super('/permissions');
  }

  // Updated method to fetch user roles with dynamic population and filtering
  async fetchPermissions(orgId: string, populate: string, filter: string) {
    const response = await api.get<Permission[]>(`${this.endpoint}?populate=${populate}&org_id=${orgId}&filter=${filter}`);
    return response.data;
  }
}

export default new PermissionService();
