// src/api/services/auth.service.ts

import api from '../axios';
import { LoginResponse, User } from '@/models/auth.types';
import { setToken } from '../../utils/auth.utils';
import { API_ENDPOINTS } from '../endpoints';

class AuthService {
  async login(email: string, password: string): Promise<LoginResponse> {
    const formData = new FormData();
    formData.append('username', email);
    formData.append('password', password);

    const response = await api.post(API_ENDPOINTS.AUTH.LOGIN, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.access_token) {
      setToken(response.data.access_token);
    }

    return response.data;
  }

  async register(userData: Partial<User>): Promise<User> {
    const response = await api.post(API_ENDPOINTS.AUTH.REGISTER, userData);
    return response.data;
  }

  async getCurrentUser(): Promise<User> {
    const response = await api.get(API_ENDPOINTS.AUTH.CURRENT_USER);
    return response.data;
  }
}

export default new AuthService();
