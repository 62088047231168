// src/api/services/route.service.ts
import { Route, RouteCreate, RouteUpdate } from '../../models/route.types';
import { BaseService } from './base.service';
import { API_ENDPOINTS } from '../endpoints';
import api from '../axios';

class RouteService extends BaseService<Route, RouteCreate, RouteUpdate> {
  constructor() {
    super(API_ENDPOINTS.ROUTES.BASE);
  }

  // Updated method to fetch locations with dynamic populate and filter parameters
  async fetchRoutes(orgId: string, populate: string, filter: string) {
    const response = await api.get<Route[]>(`${this.endpoint}?populate=${populate}&org_id=${orgId}&filter=${filter}`);
    return response.data;
  }
}

export default new RouteService();
