import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@/components/atoms/Card';
import reportsService from '@/api/services/reports.service'
import GenericTable from '@/components/atoms/GenericTable';
import { useAuth } from '@/contexts/AuthContext';

interface PickupManagementTableProps {
  orgId: string; // Add orgId as a prop
  populate: string; // Add populate as a prop
  filter: string; // Add filter as a prop
  selectedMonth: string;
  status: string;
  onMonthChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  monthOptions: Array<{ value: string; label: string }>;
  statusOptions: Array<{ value: string; label: string }>;
  className?: string;
  'data-testid'?: string;
}

export const PickupManagementTable: React.FC<PickupManagementTableProps> = ({
  selectedMonth,
  status,
  onMonthChange,
  onStatusChange,
  monthOptions,
  statusOptions,
  className,
  'data-testid': testId = 'centres-table',
  ...props
}) => {
  const [trips, setLocations] = useState<any[]>([]); // State to hold trips data
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    const fetchTripsData = async () => {
      try {
        const populate = 'route'; // Specify the fields to populate
        const filter = ''; // Specify the filter
        const orgId = user?.org_id
        const fetchedTrips = await reportsService.getPickupManagementReports(orgId); // Fetch trips
        setLocations(fetchedTrips);
      } catch (err) {
        setError('Failed to fetch trips');
      } finally {
        setLoading(false);
      }
    };

    fetchTripsData();
  }, []); // Dependencies for useEffect

  if (loading) return <div>Loading trips...</div>;
  if (error) return <div>{error}</div>;

  const columns = [
    { header: 'Center Name', accessor: 'center_name', sortable: true},
    { header: 'Total Weight', accessor: 'total_weight', sortable: true },
    { header: 'QA Agent', accessor: 'qa_agents', sortable: true, render: (item) => item.qa_agents ? item.qa_agents.length : 'N/A' },
    { header: 'Farmers Processed', accessor: 'farmers_processed', sortable: true },
    { header: 'Hand Over (KG)', accessor: 'handed_over_weight', sortable: true },
    { header: 'Rejected (KG)', accessor: 'rejected_weight', sortable: true },
  ];

  const handleRowButtonClick = (item: any) => {
    console.log('Row action clicked for:', item);
    // Implement your action logic here
  };

  return (
    <Card className={className} data-testid={testId} {...props}>
      <CardContent className="p-4">
        <GenericTable
          data={trips}
          columns={columns}
          testId={`${testId}-table`}
        />
      </CardContent>
    </Card>
  );
};


