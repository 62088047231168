import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@/components/atoms/Card';
import permissionService from '@/api/services/permission.service'; // Import the PermissionService
import { Permission } from '@/models/permission.types'; // Import the Permission type
import GenericTable from '@/components/atoms/GenericTable';
import { useAuth } from '@/contexts/AuthContext';

interface PermissionsTableProps {
  selectedMonth: string;
  status: string;
  onMonthChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  monthOptions: Array<{ value: string; label: string }>;
  statusOptions: Array<{ value: string; label: string }>;
  className?: string;
  'data-testid'?: string;
}

export const PermissionsTable: React.FC<PermissionsTableProps> = ({
  selectedMonth,
  status,
  onMonthChange,
  onStatusChange,
  monthOptions,
  statusOptions,
  className,
  'data-testid': testId = 'centres-table',
  ...props
}) => {
  const [users, setPermissions] = useState<Permission[]>([]); // State to hold user roles
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const populate = ''; // Specify the fields to populate
        const filter = ''; // Specify the filter
        const orgId = user?.org_id
        const roles = await permissionService.fetchPermissions(orgId, populate, filter)
        setPermissions(roles);
      } catch (err) {
        setError('Failed to fetch permissions');
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, []);

  if (loading) return <div>Loadingpermission roles...</div>;
  if (error) return <div>{error}</div>;

  const columns = [
    { header: 'Name', accessor: 'name', sortable: true },
    { header: 'Description', accessor: 'description', sortable: true },
    { header: 'resource', accessor: 'resource', sortable: true },
    { header: 'Action', accessor: 'action', sortable: true },
  ];

  const handleActionClick = (actionType: string, item: any) => {
    console.log(`Action ${actionType} clicked for:`, item);
    // Implement your action logic here
  };

  const renderRowActions = (item: any) => {
    const actions = [
      { type: 'button', label: 'Edit', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-4 mx-1 rounded' },
      // { type: 'Call', label: 'Call', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-1 mx-1 rounded' },
      // { type: 'View Progress', label: 'View Progress', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-1 mx-1 rounded' },
    ];

    if (actions.length === 0) {
      return null;
    }

    return (
      <>
        {actions.map(action => (
          <button
            key={action.type}
            onClick={() => handleActionClick(action.type, item)}
            className={action.className} // Apply Tailwind CSS classes
          >
            {action.label}
          </button>
        ))}
      </>
    );
  };

  return (
    <Card className={className} data-testid={testId} {...props}>
      <CardContent className="p-4">
        <GenericTable
          data={users} // Use fetchedpermission roles data
          columns={columns}
          testId={`${testId}-table`}
          renderRowActions={renderRowActions}
        />
      </CardContent>
    </Card>
  );
};
