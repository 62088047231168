// import React from 'react';
// import { Card, CardContent } from "@/components/atoms/Card";

// interface KPICardProps {
//   title: string;
//   value: string;
//   change: string;
//   icon: string;
//   trend: 'up' | 'down';
//   valueColor?: string;
// }

// export const KPICard = ({ title, value, change, icon, trend, valueColor }: KPICardProps) => (
//   <Card>
//     <CardContent className="p-4">
//       <div className="flex justify-between items-start">
//         <div>
//           <p className="text-sm text-gray-500">{title}</p>
//           <p className={`text-2xl font-bold ${valueColor || ''}`}>{value}</p>
//         </div>
//         <span className="text-2xl" role="img" aria-hidden="true">{icon}</span>
//       </div>
//       <p className={`text-xs ${trend === 'up' ? 'text-green-600' : 'text-red-600'} mt-2`}>
//         {change}
//       </p>
//     </CardContent>
//   </Card>
// );

// src/components/molecules/KPICard.tsx
import React from 'react';
import { Card, CardContent } from "@/components/atoms/Card";

interface KPICardProps {
  title: string;
  value: string;
  change: string;
  icon: string;
  trend: 'up' | 'down';
  valueColor?: string;
  className?: string;
  'data-testid'?: string;
}

export const KPICard: React.FC<KPICardProps> = ({ 
  title, 
  value, 
  change, 
  icon, 
  trend, 
  valueColor,
  className,
  'data-testid': testId,
  ...props
}) => (
  <Card className={className} data-testid={testId} {...props}>
    <CardContent className="p-4">
      <div className="flex justify-between items-start">
        <div>
          <p className="text-sm text-gray-500" data-testid={`${testId}-title`}>{title}</p>
          <p className={`text-2xl font-bold ${valueColor || ''}`} data-testid={`${testId}-value`}>
            {value}
          </p>
        </div>
        <span className="text-2xl" role="img" aria-label={title} data-testid={`${testId}-icon`}>
          {icon}
        </span>
      </div>
      <p 
        className={`text-xs ${trend === 'up' ? 'text-green-600' : 'text-red-600'} mt-2`}
        data-testid={`${testId}-change`}
      >
        {change}
      </p>
    </CardContent>
  </Card>
);