import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@/components/atoms/Card';
import { FarmersProfileReport } from '@/models/report.types'; // Import the FarmersProfileReport type
import GenericTable from '@/components/atoms/GenericTable';
import { useAuth } from '@/contexts/AuthContext';
import reportsService from '@/api/services/reports.service'
import { formatDate } from '@/utils/date.utils';

interface FarmersProfilesTableProps {
  selectedMonth: string;
  status: string;
  onMonthChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  monthOptions: Array<{ value: string; label: string }>;
  statusOptions: Array<{ value: string; label: string }>;
  className?: string;
  'data-testid'?: string;
}

export const FarmersProfilesTable: React.FC<FarmersProfilesTableProps> = ({
  selectedMonth,
  status,
  onMonthChange,
  onStatusChange,
  monthOptions,
  statusOptions,
  className,
  'data-testid': testId = 'centres-table',
  ...props
}) => {
  const [farmersReport, setFarmersProfileReports] = useState<FarmersProfileReport[]>([]); // State to hold user roles
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    const fetchDriverRoles = async () => {
      try {
        const populate = ''; // Specify the fields to populate
        const filter = ''; // Specify the filter
        const orgId = user?.org_id
        const farmersReports = await reportsService.getFarmersProfileReports(orgId)
        setFarmersProfileReports(farmersReports);
      } catch (err) {
        setError('Failed to fetch user roles');
      } finally {
        setLoading(false);
      }
    };

    fetchDriverRoles();
  }, []);

  if (loading) return <div>Loading user roles...</div>;
  if (error) return <div>{error}</div>;

  const columns = [
    { header: 'Member Number', accessor: 'member_number', sortable: true },
    { header: 'Farmer Name', accessor: 'farmer_name', sortable: true},
    { header: 'Date Of Last', accessor: 'date_of_last_collection', sortable: true, render: (item) => formatDate(item.date_of_last_collection) },
    { header: 'Quantity Supplied', accessor: 'quantity_supplied', sortable: true },
    { header: 'Average Quality Scored', accessor: 'average_quality_score', sortable: true },
    { header: 'Payment', accessor: 'payment_status', sortable: true },
    // { header: 'Payment', accessor: 'location', sortable: true },
    // { header: 'Farmer Rating', accessor: 'location', sortable: true },
  ];

  return (
    <Card className={className} data-testid={testId} {...props}>
      <CardContent className="p-4">
        <GenericTable
          data={farmersReport}
          columns={columns}
          testId={`${testId}-table`}
        />
      </CardContent>
    </Card>
  );
};
