import React from 'react';
import { ButtonProps } from './Button.types';

const getVariantClasses = (variant: ButtonProps['variant']) => {
  switch (variant) {
    case 'secondary':
      return 'bg-white text-gray-700 border border-gray-300 hover:bg-gray-50';
    case 'ghost':
      return 'bg-transparent hover:bg-gray-100 text-gray-700';
    default:
      return 'bg-blue-600 text-white hover:bg-blue-700';
  }
};

const getSizeClasses = (size: ButtonProps['size']) => {
  switch (size) {
    case 'small':
      return 'px-3 py-1.5 text-sm';
    case 'large':
      return 'px-6 py-3 text-lg';
    case 'icon':
      return 'p-2';
    default:
      return 'px-4 py-2';
  }
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ 
    children, 
    variant = 'primary', 
    size = 'default', 
    isLoading,
    className = '',
    disabled,
    ...props 
  }, ref) => (
    <button
      ref={ref}
      disabled={disabled || isLoading}
      className={`
        inline-flex items-center justify-center
        font-medium rounded-md
        transition-colors
        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
        disabled:opacity-50 disabled:cursor-not-allowed
        ${getVariantClasses(variant)}
        ${getSizeClasses(size)}
        ${className}
      `}
      {...props}
    >
      {isLoading && (
        <svg
          className="animate-spin -ml-1 mr-2 h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
          />
        </svg>
      )}
      {children}
    </button>
  )
);

Button.displayName = 'Button';
