import React, { useState, useMemo } from 'react';
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react'; // Import icons

interface Column<T> {
  header: string;
  accessor: keyof T; // Key in the data object
  sortable?: boolean; // Indicate if the column is sortable
  render?: (item: T) => React.ReactNode; // Custom render function for cells
}

interface GenericTableProps<T> {
  data: T[];
  columns: Column<T>[];
  className?: string;
  testId?: string;
  renderRowActions?: (item: T) => React.ReactNode; // Keep this prop
  itemsPerPage?: number; // Optional prop for items per page
}

const GenericTable = <T,>({
  data,
  columns,
  className,
  testId,
  renderRowActions,
  itemsPerPage = 10, // Default items per page
}: GenericTableProps<T>) => {
  const [sortConfig, setSortConfig] = useState<{ key: keyof T; direction: 'ascending' | 'descending' } | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // State for current page

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = useMemo(() => {
    return sortedData.filter(item => {
      return columns.some(column => {
        const value = item[column.accessor];
        return typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase());
      });
    });
  }, [sortedData, searchTerm, columns]);

  // Calculate paginated data
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, startIndex + itemsPerPage);

  const requestSort = (key: keyof T) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Dynamically add the Actions column if renderRowActions is provided
  const finalColumns = renderRowActions ? [...columns, { header: 'Actions', accessor: 'actions', sortable: false }] : columns;

  return (
    <div className={`overflow-x-auto ${className}`} data-testid={testId}>
      <table className="min-w-full">
        <thead>
          <tr className="bg-gray-50">
            {finalColumns.map((column, index) => (
              <th key={index} className="px-4 py-3 text-left text-sm font-medium text-gray-500">
                <button
                  type="button"
                  onClick={() => column.sortable && requestSort(column.accessor)}
                  className="flex items-center"
                >
                  {column.header}
                  {column.sortable && (
                    <span className="ml-1">
                      {sortConfig?.key === column.accessor ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
                    </span>
                  )}
                </button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {paginatedData.map((item, index) => (
            <tr key={index}>
              {finalColumns.map((column) => (
                <td key={column.accessor as string} className="px-4 py-3 text-sm">
                  {column.accessor === 'actions' && renderRowActions ? (
                    renderRowActions(item) // Call the renderRowActions function for the actions column
                  ) : column.render ? (
                    column.render(item) // Call the custom render function if provided
                  ) : (
                    item[column.accessor] // Default rendering
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex justify-end items-center mt-4">
        <button
          onClick={() => setCurrentPage(1)} // Go to first page
          disabled={currentPage === 1}
          className="p-2 text-gray-700 disabled:opacity-50"
        >
          <ChevronsLeft />
        </button>
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} // Go to previous page
          disabled={currentPage === 1}
          className="p-2 text-gray-700 disabled:opacity-50"
        >
          <ChevronLeft />
        </button>
        <span className="mx-2">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} // Go to next page
          disabled={currentPage === totalPages}
          className="p-2 text-gray-700 disabled:opacity-50"
        >
          <ChevronRight />
        </button>
        <button
          onClick={() => setCurrentPage(totalPages)} // Go to last page
          disabled={currentPage === totalPages}
          className="p-2 text-gray-700 disabled:opacity-50"
        >
          <ChevronsRight />
        </button>
      </div>
    </div>
  );
};

export default GenericTable;


