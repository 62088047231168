import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@/components/atoms/Card';
import { useCollection } from '@/contexts/CollectionContext';
import GenericTable from '@/components/atoms/GenericTable';
import reportsService from '@/api/services/reports.service'
import { useAuth } from '@/contexts/AuthContext';

interface RejectionLogsTableProps {
  selectedMonth: string;
  status: string;
  onMonthChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  monthOptions: Array<{ value: string; label: string }>;
  statusOptions: Array<{ value: string; label: string }>;
  className?: string;
  'data-testid'?: string;
}

export const RejectionLogsTable: React.FC<RejectionLogsTableProps> = ({
  selectedMonth,
  status,
  onMonthChange,
  onStatusChange,
  monthOptions,
  statusOptions,
  className,
  'data-testid': testId = 'centres-table',
  ...props
}) => {
  const [rejectionLogs, setRejectionLogs] = useState<any[]>([]); // State to hold rejectionLogs data
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    const fetchRejectionLogsData = async () => {
      try {
        const populate = ''; // Specify the fields to populate
        const filter = ''; // Specify the filter
        const orgId = user?.org_id
        const fetchedRejectionLogs = await reportsService.getRejectionLogs(orgId); // Fetch rejectionLogs schedule
        setRejectionLogs(fetchedRejectionLogs);
      } catch (err) {
        setError('Failed to fetch rejectionLogs');
      } finally {
        setLoading(false);
      }
    };

    fetchRejectionLogsData();
  }, []); // Dependencies for useEffect

  if (loading) return <div>Loading rejectionLogs...</div>;
  if (error) return <div>{error}</div>;

  const columns = [
    { header: 'Date', accessor: 'date', sortable: true,  render: (item) => formatDate(item.date) },
    { header: 'Collection Center', accessor: 'collection_center', sortable: true },
    { header: 'QA Agent', accessor: 'qa_agent', sortable: true },
    { header: 'Reason', accessor: 'reason', sortable: true },
    { header: 'Farmer Name', accessor: 'farmer_name', sortable: true },
    { header: 'Weight', accessor: 'weight', sortable: true },
    { header: 'Batch No.', accessor: 'batch_number', sortable: true },
  ];

  return (
    <Card className={className} data-testid={testId} {...props}>
      <CardContent className="p-4">
        <GenericTable
          data={rejectionLogs}
          columns={columns}
          testId={`${testId}-table`}
        />
      </CardContent>
    </Card>
  );
};
