export const MONTH_OPTIONS = [
  { value: 'october', label: 'October' },
  { value: 'november', label: 'November' },
  { value: 'december', label: 'December' },
];

export const STATUS_OPTIONS = [
  { value: 'all', label: 'All Status' },
  { value: 'active', label: 'Active' },
  { value: 'pending', label: 'Pending' },
  { value: 'completed', label: 'Completed' },
];

export const KPI_DATA: Array<{
  title: string;
  value: string;
  change: string;
  icon: string;
  trend: 'up' | 'down';
}> = [
  {
    title: 'Total Collected',
    value: '40,689',
    change: '8.5% Up from Sep',
    icon: '👥',
    trend: 'up'
  },
  { 
    title: 'Fat Content', 
    value: '1.25', 
    change: '1.3% Down From Sep', 
    icon: '🧈' 
  },
  { 
    title: 'Protein', 
    value: '1.5%', 
    change: '4.3% Down from Sep', 
    icon: '📊', 
    valueColor: 'text-red-500' 
  },
  { 
    title: 'SCC', 
    value: '176', 
    change: '1.8% Down from Sep', 
    icon: '🦠' 
  },
  { 
    title: 'Pending Payments', 
    value: '19', 
    change: '1.8% Up from Sep', 
    icon: '💰' 
  },
];

export const CENTRES_DATA = [
  {
    name: 'Kwa Njoro',
    quantity: '500,000',
    fatContent: '6',
    protein: '3',
    scc: '40',
    location: 'Denderu'
  }
];