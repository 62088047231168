// src/api/services/user.service.ts
import { User, UserCreate, UserUpdate } from '../../models/user.types';
import api from '../axios';
import { API_ENDPOINTS } from '../endpoints';
import { BaseService } from './base.service';

class UserService extends BaseService<User, UserCreate, UserUpdate> {
  constructor() {
    super(API_ENDPOINTS.USERS.BASE);
  }

  // Additional user-specific methods
  async getCurrentUser(): Promise<User> {
    const response = await api.get<User>(API_ENDPOINTS.USERS.ME);
    return response.data;
  }

  async updatePassword(id: string, oldPassword: string, newPassword: string): Promise<void> {
    await api.post(`${this.endpoint}/${id}/password`, {
      old_password: oldPassword,
      new_password: newPassword,
    });
  }

  // Updated method to fetch user roles with dynamic population and filtering
  async fetchUsers(orgId: string, populate: string, filter: string) {
    const response = await api.get<User[]>(`${this.endpoint}?populate=${populate}&org_id=${orgId}&filter=${filter}`);
    return response.data;
  }
}

export default new UserService();
