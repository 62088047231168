import React from 'react';
import { Bell } from 'lucide-react';
import { UserProfile } from '../UserProfile/UserProfile';

interface DashboardHeaderProps {
  'data-testid'?: string;
}

export const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  'data-testid': testId = 'dashboard-header'
}) => {
  return (
    <header
      className="bg-white shadow-sm px-6 py-4 flex justify-end items-center"
      data-testid={testId}
    >
      <div className="flex items-center space-x-4">
        <button
          className="p-2 hover:bg-gray-100 rounded-full"
          data-testid={`${testId}-notifications`}
          title="Notifications"
        >
          <Bell size={20} />
        </button>
        <UserProfile data-testid={`${testId}-profile`} />
      </div>
    </header>
  );
};
