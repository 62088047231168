import React, { useEffect } from 'react';
import { Card, CardContent } from '@/components/atoms/Card';
import { FilterControls } from '../../molecules/FilterControls';
import { useCollection } from '@/contexts/CollectionContext';
import GenericTable from '@/components/atoms/GenericTable';

interface CentresTableProps {
  selectedMonth: string;
  status: string;
  onMonthChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  monthOptions: Array<{ value: string; label: string }>;
  statusOptions: Array<{ value: string; label: string }>;
  className?: string;
  'data-testid'?: string;
}

export const CentresTable: React.FC<CentresTableProps> = ({
  selectedMonth,
  status,
  onMonthChange,
  onStatusChange,
  monthOptions,
  statusOptions,
  className,
  'data-testid': testId = 'centres-table',
  ...props
}) => {
  const { items, fetchItems, loadingCollections, errorFetchingCollections } = useCollection();

  useEffect(() => {
    fetchItems();
  }, []);

  if (loadingCollections) return <div>Loading collections...</div>;
  if (errorFetchingCollections) return <div>{errorFetchingCollections}</div>;

  const columns = [
    { header: 'Name', accessor: 'data_source', sortable: true },
    { header: 'Quantity (L)', accessor: 'status', sortable: true },
    { header: 'Fat Content (%)', accessor: 'fatContent', sortable: true },
    { header: 'Protein (%)', accessor: 'protein', sortable: true },
    { header: 'SCC', accessor: 'scc', sortable: true },
    { header: 'Location', accessor: 'location', sortable: true },
  ];

  const handleRowButtonClick = (item: any) => {
    console.log('Row action clicked for:', item);
    // Implement your action logic here
  };

  return (
    <Card className={className} data-testid={testId} {...props}>
      <CardContent className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold" data-testid={`${testId}-title`}>Collection Centres</h2>
          <FilterControls
            monthOptions={monthOptions}
            statusOptions={statusOptions}
            selectedMonth={selectedMonth}
            status={status}
            onMonthChange={onMonthChange}
            onStatusChange={onStatusChange}
            data-testid={`${testId}-filters`}
          />
        </div>
        <GenericTable
          data={items}
          columns={columns}
          testId={`${testId}-table`}
          onRowButtonClick={handleRowButtonClick}
        />
      </CardContent>
    </Card>
  );
};
