import * as React from 'react';
import type { SelectProps } from './Select.types';

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ options, className = '', placeholder, ...props }, ref) => {
    return (
      <select
        ref={ref}
        className={`block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${className}`}
        {...props}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  }
);

Select.displayName = 'Select';

