import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@/components/atoms/Card';
import GenericTable from '@/components/atoms/GenericTable';
import { useAuth } from '@/contexts/AuthContext';
import { QualityTest } from '@/models/report.types';
import reportsService from '@/api/services/reports.service'
import { formatDate } from '@/utils/date.utils';

interface QualityTestsTableProps {
  selectedMonth: string;
  status: string;
  onMonthChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  monthOptions: Array<{ value: string; label: string }>;
  statusOptions: Array<{ value: string; label: string }>;
  className?: string;
  'data-testid'?: string;
}

export const QualityTestsTable: React.FC<QualityTestsTableProps> = ({
  selectedMonth,
  status,
  onMonthChange,
  onStatusChange,
  monthOptions,
  statusOptions,
  className,
  'data-testid': testId = 'quality-tests-table',
  ...props
}) => {
  const [qualityTests, setQualityTests] = useState<QualityTest[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    const fetchQualityTests = async () => {
      try {
        const orgId = user?.org_id;
        const tests = await reportsService.getQualityTests(orgId);
        setQualityTests(tests);
      } catch (err) {
        setError('Failed to fetch quality tests');
      } finally {
        setLoading(false);
      }
    };

    fetchQualityTests();
  }, [user]);

  if (loading) return <div>Loading quality tests...</div>;
  if (error) return <div>{error}</div>;

  // {
  //   "test_date": "2024-11-28T12:38:08.011541",
  //   "collection_center": "Cooperative 1 Satellite Collection Point 1",
  //   "qa_agent": null,
  //   "batch_number": null,
  //   "test_results": {},
  //   "overall_result": "Approved"
  // },

  const columns = [
    { header: 'Test Date', accessor: 'test_date', sortable: true },
    { header: 'Collection Center', accessor: 'collection_center', sortable: true },
    { header: 'QA Agent', accessor: 'qa_agent', sortable: true },
    { header: 'Batch Number', accessor: 'batch_number', sortable: true },
    { header: 'Overall Result', accessor: 'overall_result', sortable: true },
  ];

  return (
    <Card className={className} data-testid={testId} {...props}>
      <CardContent className="p-4">
        <GenericTable
          data={qualityTests}
          columns={columns}
          testId={`${testId}-table`}
        />
      </CardContent>
    </Card>
  );
};
