// src/api/services/role.service.ts
import { Role, RoleCreate, RoleUpdate } from '../../models/role.types';
import { BaseService } from './base.service';
import api from '../axios';
import { API_ENDPOINTS } from '../endpoints';

class RoleService extends BaseService<Role, RoleCreate, RoleUpdate> {
  constructor() {
    super(API_ENDPOINTS.ROLES.BASE);
  }

  // Updated method to fetch user roles with dynamic population and filtering
  async fetchRoles(orgId: string, populate: string, filter: string) {
    const response = await api.get<Role[]>(`${this.endpoint}?populate=${populate}&org_id=${orgId}&filter=${filter}`);
    return response.data;
  }
}

export default new RoleService();
