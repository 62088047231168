import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, LogOut, Settings } from 'lucide-react';
import { Button } from '@/components/atoms/Button';
import cn from 'classnames';

const sidebarItems = [
  { name: 'Dashboard', icon: '📊', path: '/dashboard' },
  { name: 'Milk Collection', icon: '🥛', subItems: [
      { name: 'Collection Schedule', path: '/milk-collections/collection-schedule' },
      { name: 'Driver Tracking',  path: '/milk-collections/driver-tracking' },
      { name: 'Pickup Management', path: '/milk-collections/pickup-management'},
      'Inventory'
    ]
  },
  { name: 'Quality Control', icon: '🔬', subItems: [
      {name: 'Quality Tests (On-Site)', path: '/quality-control/quality-tests'},
      {name: 'Rejection Logs', path: '/quality-control/rejection-logs'},
    ]
  },
  { name: 'Farmers', icon: '👨‍🌾', subItems: [
      { name: 'Farmer Profiles', path: '/farmers/farmers-profiles'}
    ]
  },
  { name: 'Payroll', icon: '💰', subItems: [
      {name: 'Pending Payments', path: '/payroll/pending-payments'},
      {name: 'Completed Payments', path: '/payroll/completed-payments'}
    ]
  },
  { name: 'Feed Management', icon: '🌾' },
  { name: 'Logistics Management', icon: '🚚', subItems: [
      {name: 'Driver Management', path: '/logistics-management/driver-management'},
      {name: 'Routes Management', path: '/logistics-management/routes-management'},
      {name: 'Collection Centers & QA Agents', path: '/logistics-management/collection-centers-and-qa-agents'},
    ]
  },
  { name: 'User Management', icon: '🚚', subItems: [
      {name: 'Users', path: '/user-management/users'},
      {name: 'Roles', path: '/user-management/roles'},
      {name: 'Permissions', path: '/user-management/permissions'},
    ]
  },
  { name: 'Extension Services', icon: '📞', subItems: ['Assignments', 'Call Logs'] },
  { name: 'Reports', icon: '📈', subItems: ['Quality', 'Milk Volume', 'Extension Services'] },
] as const;

interface SidebarProps {
  className?: string;
  'data-testid'?: string;
}

export const Sidebar: React.FC<SidebarProps> = ({
  className,
  'data-testid': testId = 'sidebar',
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [activeItem, setActiveItem] = useState('Dashboard');

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
      setIsOpen(window.innerWidth >= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleItemClick = (itemName: string) => {
    setActiveItem(activeItem === itemName ? '' : itemName);
  };

  return (
    <aside
      className={cn(
        "w-64 bg-white shadow-md min-h-screen",
        "transition-all duration-300",
        "fixed md:relative",
        {
          "translate-x-0": isOpen,
          "-translate-x-full": !isOpen,
          "md:translate-x-0": !isMobile
        },
        className
      )}
      data-testid={testId}
      {...props}
    >
      <div className="p-4" data-testid={`${testId}-header`}>
        <h1 className="text-2xl font-bold text-green-600">Farmwise</h1>
      </div>

      <nav className="mt-4" data-testid={`${testId}-nav`}>
        {sidebarItems.map((item) => (
          <div key={item.name} data-testid={`${testId}-item-${item.name.toLowerCase()}`}>
            <Link
              to={item.path}
              className={cn(
                "flex items-center w-full px-4 py-2 text-left",
                "transition-colors duration-200",
                {
                  'bg-green-100 text-green-600': activeItem === item.name,
                  'hover:bg-gray-100': activeItem !== item.name
                }
              )}
              onClick={() => handleItemClick(item.name)}
              data-testid={`${testId}-button-${item.name.toLowerCase()}`}
            >
              <span className="mr-2" role="img" aria-hidden="true">{item.icon}</span>
              {item.name}
              {item.subItems && (
                <ChevronDown
                  className={cn(
                    "ml-auto transition-transform duration-200",
                    { 'transform rotate-180': activeItem === item.name }
                  )}
                  size={16}
                />
              )}
            </Link>
            {item.subItems && activeItem === item.name && (
              <div
                className="ml-8 mt-1"
                data-testid={`${testId}-subitems-${item.name.toLowerCase()}`}
              >
                {item.subItems.map((subItem) => (
                  typeof subItem === 'string' ? (
                    <button
                      key={subItem}
                      className="block w-full px-4 py-2 text-sm text-left hover:bg-gray-100 transition-colors duration-200"
                      data-testid={`${testId}-subitem-${subItem.toLowerCase().replace(/\s+/g, '-')}`}
                    >
                      {subItem}
                    </button>
                  ) : (
                    <Link
                      key={subItem.name}
                      to={subItem.path}
                      className="block w-full px-4 py-2 text-sm text-left hover:bg-gray-100 transition-colors duration-200"
                      data-testid={`${testId}-subitem-${subItem.name.toLowerCase().replace(/\s+/g, '-')}`}
                    >
                      {subItem.name}
                    </Link>
                  )
                ))}
              </div>
            )}
          </div>
        ))}
      </nav>

      <div className="absolute bottom-0 w-64 p-4" data-testid={`${testId}-footer`}>
        <Button
          variant="ghost"
          className="w-full flex items-center justify-center"
          data-testid={`${testId}-settings-button`}
        >
          <Settings className="mr-2" size={16} />
          Settings
        </Button>
        <Button
          variant="ghost"
          className="w-full mt-2 flex items-center justify-center"
          data-testid={`${testId}-logout-button`}
        >
          <LogOut className="mr-2" size={16} />
          Logout
        </Button>
      </div>
    </aside>
  );
};
