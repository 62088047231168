import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@/components/atoms/Card';
import reportsService from '@/api/services/reports.service'
import { CompletedPaymentReport } from '@/models/report.types';
import GenericTable from '@/components/atoms/GenericTable';
import { formatDate } from '@/utils/date.utils'; // Import the formatDate function
import { useAuth } from '@/contexts/AuthContext';

interface CompletedPaymentsTableProps {
  orgId: string; // Add orgId as a prop
  populate: string; // Add populate as a prop
  filter: string; // Add filter as a prop
  selectedMonth: string;
  status: string;
  onMonthChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  monthOptions: Array<{ value: string; label: string }>;
  statusOptions: Array<{ value: string; label: string }>;
  className?: string;
  'data-testid'?: string;
}

export const CompletedPaymentsTable: React.FC<CompletedPaymentsTableProps> = ({
  selectedMonth,
  status,
  onMonthChange,
  onStatusChange,
  monthOptions,
  statusOptions,
  className,
  'data-testid': testId = 'centres-table',
  ...props
}) => {
  const [payments, setPayments] = useState<CompletedPaymentReport[]>([]); // State to hold payments data
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    const fetchPaymentsData = async () => {
      try {
        const populate = ''; // Specify the fields to populate
        const filter = ''; // Specify the filter
        const orgId = user?.org_id
        const fetchedPayments = await reportsService.getCompletedPaymentsReports(orgId); // Fetch payments
        setPayments(fetchedPayments);
      } catch (err) {
        setError('Failed to fetch payments');
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentsData();
  }, []); // Dependencies for useEffect

  if (loading) return <div>Loading payments...</div>;
  if (error) return <div>{error}</div>;

  const columns = [
    { header: 'Payment Date', accessor: 'payment_date', sortable: true, render: (item) => formatDate(item.payment_date)  },
    { header: 'Farmer Name', accessor: 'farmer_name', sortable: true },
    { header: 'Member Number', accessor: 'member_id', sortable: true },
    { header: 'Amount Paid', accessor: 'amount_paid', sortable: true },
    {
      header: 'Payment Method',
      accessor: 'payment_method', // Keep the accessor as is
      sortable: true,
      render: (item) => item.payment_method.replace(/_/g, ' '), // Replace underscores with spaces
    },
    { header: 'Transaction Reference', accessor: 'transaction_reference', sortable: true },
    { header: 'Payment Status', accessor: 'payment_status', sortable: true },
    // { header: 'Actions', accessor: 'location', sortable: true },
  ];

  return (
    <Card className={className} data-testid={testId} {...props}>
      <CardContent className="p-4">
        <GenericTable
          data={payments}
          columns={columns}
          testId={`${testId}-table`}
        />
      </CardContent>
    </Card>
  );
};
