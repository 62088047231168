// src/utils/auth.utils.ts
const TOKEN_KEY = 'AylNFPXLpFY9uMGFyRpgS1ZgHTTNjU544Ukk3GeURxQ=';

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const setToken = (token: string) => localStorage.setItem(TOKEN_KEY, token);

export const removeToken = () => localStorage.removeItem(TOKEN_KEY);

export const refreshToken = async () => {
  // Implement token refresh logic here
  // For demonstration, we'll just remove the token
  removeToken();
  return null;
};
