export const API_ENDPOINTS = {
    AUTH: {
      LOGIN: '/token',
      REGISTER: '/register',
      CURRENT_USER: '/users/me',
    },
    COLLECTIONS: {
      BASE: '/collections',
      BY_ID: (id: string) => `/collections/${id}`,
    },
    CONSOLIDATIONS: {
      BASE: '/consolidations',
      BY_ID: (id: string) => `/consolidations/${id}`,
    },
    ORGANIZATIONS: {
      BASE: '/organizations',
      BY_ID: (id: string) => `/organizations/${id}`,
    },
    USERS: {
      BASE: '/users',
      BY_ID: (id: string) => `/users/${id}`,
      ME: '/users/me',
    },
    ROLES: {
        BASE: '/roles',
        BY_ID: (id: string) => `/roles/${id}`,
    },
    ROUTES: {
        BASE: '/routes',
        BY_ID: (id: string) => `/routes/${id}`,
    },
    TRIPS: {
        BASE: '/trips',
        BY_ID: (id: string) => `/trips/${id}`,
    },
  };
