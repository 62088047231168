import { BaseService } from './base.service';
import { Report,
  DriverTrackingReport,
  PickupManagementReport,
  RejectionLog,
  FarmersProfileReport,
  PendingPaymentReport,
  CompletedPaymentReport,
  DriverManagementReport,
  RoutesAndCollectionCentersReport,
  QualityTest } from '../../models/report.types'; // Adjust the import based on your project structure
import api from '../axios'; // Import the axios instance for making requests

class ReportsService extends BaseService<Report> {
  constructor() {
    super('/reports'); // Set the base endpoint for reports
  }

  // Method to get collection schedule reports with optional organization_id
  async getCollectionScheduleReports(organizationId?: string): Promise<Report[]> {
    const url = organizationId
      ? `/reports/collection-schedule?organization_id=${organizationId}`
      : '/reports/collection-schedule';

    const response = await api.get<Report[]>(url);
    return response.data;
  }

  // Method to get driver tracking reports with optional organization_id
  async getDriverTrackingReports(organizationId?: string): Promise<DriverTrackingReport[]> {
    const url = organizationId
      ? `/reports/driver-tracking?organization_id=${organizationId}`
      : '/reports/driver-tracking';

    const response = await api.get<DriverTrackingReport[]>(url);
    return response.data;
  }

  // Method to get pickup management reports with optional organization_id
  async getPickupManagementReports(organizationId?: string): Promise<PickupManagementReport[]> {
    const url = organizationId
      ? `/reports/pickup-management?organization_id=${organizationId}`
      : '/reports/pickup-management';

    const response = await api.get<PickupManagementReport[]>(url);
    return response.data;
  }

  // Method to get rejection logs with optional organization_id
  async getRejectionLogs(organizationId?: string): Promise<RejectionLog[]> {
    const url = organizationId
      ? `/reports/rejection-logs?organization_id=${organizationId}`
      : '/reports/rejection-logs';

    const response = await api.get<RejectionLog[]>(url);
    return response.data;
  }

  // Method to get farmers profile reports with optional organization_id
  async getFarmersProfileReports(organizationId?: string): Promise<FarmersProfileReport[]> {
    const url = organizationId
      ? `/reports/farmers-profile?organization_id=${organizationId}`
      : '/reports/farmers-profile';

    const response = await api.get<FarmersProfileReport[]>(url);
    return response.data;
  }

  // Method to get pending payments reports with optional organization_id
  async getPendingPaymentsReports(organizationId?: string): Promise<PendingPaymentReport[]> {
    const url = organizationId
      ? `/reports/pending-payments?organization_id=${organizationId}`
      : '/reports/pending-payments';

    const response = await api.get<PendingPaymentReport[]>(url);
    return response.data;
  }

  // Method to get completed payments reports with optional organization_id
  async getCompletedPaymentsReports(organizationId?: string): Promise<CompletedPaymentReport[]> {
    const url = organizationId
      ? `/reports/completed-payments?organization_id=${organizationId}`
      : '/reports/completed-payments';

    const response = await api.get<CompletedPaymentReport[]>(url);
    return response.data;
  }

  // Method to get driver management reports with optional organization_id
  async getDriverManagementReports(organizationId?: string): Promise<DriverManagementReport[]> {
    const url = organizationId
      ? `/reports/driver-management?organization_id=${organizationId}`
      : '/reports/driver-management';

    const response = await api.get<DriverManagementReport[]>(url);
    return response.data;
  }

  // Method to get routes and collection centers reports with optional organization_id
  async getRoutesAndCollectionCentersReports(organizationId?: string): Promise<RoutesAndCollectionCentersReport[]> {
    const url = organizationId
      ? `/reports/routes-and-collection-centers?organization_id=${organizationId}`
      : '/reports/routes-and-collection-centers';

    const response = await api.get<RoutesAndCollectionCentersReport[]>(url);
    return response.data;
  }

  // Method to get quality tests with optional organization_id
  async getQualityTests(organizationId?: string): Promise<QualityTest[]> {
    const url = organizationId
      ? `/reports/quality-tests?organization_id=${organizationId}`
      : '/reports/quality-tests';

    const response = await api.get<QualityTest[]>(url);
    return response.data;
  }
}

export default new ReportsService();
