// src/contexts/CollectionContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import collectionService from '@/api/services/collection.service'; // Adjust the import based on your service path
import { Collection } from '@/models/collection.types'; // Adjusted import based on your model path

const CollectionContext = createContext<CollectionContextType | undefined>(undefined);

export const CollectionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [items, setItems] = useState<Collection[]>([]);

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const fetchedItems = await collectionService.getAll(); // Fetch all items
      setItems(fetchedItems);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const addItem = async (item: Collection) => {
    try {
      const newItem = await collectionService.create(item); // Create a new item
      setItems((prevItems) => [...prevItems, newItem]); // Add the new item to the state
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const updateItem = async (id: string, item: Collection) => {
    try {
      const updatedItem = await collectionService.update(id, item); // Update the item
      setItems((prevItems) => prevItems.map(prevItem => (prevItem.id === id ? updatedItem : prevItem))); // Update the state
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const removeItem = async (id: string) => {
    try {
      await collectionService.delete(id); // Delete the item
      setItems((prevItems) => prevItems.filter(item => item.id !== id)); // Remove the item from the state
    } catch (error) {
      console.error('Error removing item:', error);
    }
  };

  return (
    <CollectionContext.Provider value={{ items, addItem, updateItem, removeItem, fetchItems }}>
      {children}
    </CollectionContext.Provider>
  );
};

export const useCollection = () => {
  const context = useContext(CollectionContext);
  if (!context) {
    throw new Error('useCollection must be used within a CollectionProvider');
  }
  return context;
};
