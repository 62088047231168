import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { routes } from './routes/routes';
import PrivateRoute from './routes/PrivateRoute';
import { PublicRoute } from './routes/PublicRoute';
import { AuthProvider } from './contexts/AuthContext';
import { CollectionProvider } from '@/contexts/CollectionContext';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {routes.map((route, index) => {
            const { element, roles, public: isPublic, path } = route;
            if (isPublic) {
              return <Route key={index} path={path} element={<PublicRoute>{element}</PublicRoute>} />;
            } else {
              return (
                <Route
                  key={index}
                  path={path}
                  element={<PrivateRoute roles={roles}>{element}</PrivateRoute>}
                />
              );
            }
          })}
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
