'use client'
import React, { useState, useRef, useEffect } from 'react';
import { Sidebar } from '@/components/organisms/Sidebar';
import GenericForm from '@/components/atoms/GenericForm';
import { Button } from '@/components/atoms/Button';
import routeService from '@/api/services/route.service';
import { useAuth } from '@/contexts/AuthContext';
import locationService from '@/api/services/location.service';

export default function AddNewRoute() {
  const { user } = useAuth();
  const [routeData, setRouteData] = useState({
    name: '',
    location_ids: [],
    organization_id: user?.org_id || '',
    distance: 0,
    estimated_time: 0,
    start_times: {}
  });
  const [collectionCenters, setCollectionCenters] = useState([]);
  const [selectedLocationIds, setSelectedLocationIds] = useState<{ id: string; name: string }[]>([]);
  const formRef = useRef<HTMLFormElement | null>(null);
  const timeInputRef = useRef<HTMLInputElement | null>(null);
  const dayInputRef = useRef<HTMLSelectElement | null>(null);

  // Fetch collection centers when the component mounts
  useEffect(() => {
    const fetchCollectionCenters = async () => {
      const orgId = user?.org_id;
      const populate = '';
      const filter = 'type:collection_center';

      try {
        const centers = await locationService.fetchLocations(orgId, populate, filter);
        const formattedCenters = centers.map(center => ({
          value: center.id,
          label: center.name
        }));
        setCollectionCenters(formattedCenters);
      } catch (error) {
        console.error('Error fetching collection centers:', error);
      }
    };

    if (user?.org_id) {
      fetchCollectionCenters();
    }
  }, [user?.org_id]);

  const handleSubmit = async (values: Record<string, any>, method: string) => {
    // Merge the new values with the existing routeData
    setRouteData(prevData => ({ ...prevData, ...values }));

    const newRoute = {
      ...routeData,
      name: values['name'],
      location_ids: selectedLocationIds.map(location => location.id),
      distance: values['distance'],
      estimated_time: values['estimated_time'],
      start_times: { ...routeData.start_times }
    };

    await routeService.create(newRoute).then((resp) => {
      console.log('Route created', resp);
      // Reset all fields
      setRouteData({
        name: '',
        location_ids: [],
        organization_id: user?.org_id || '',
        distance: 0,
        estimated_time: 0,
        start_times: {}
      });
      setSelectedLocationIds([]);
      if (formRef.current) {
        formRef.current.reset();
      }
    }).catch(error => {
      console.log('Error creating route', error);
    });
  };

  const addStartTime = () => {
    const selectedDay = dayInputRef.current?.value;
    if (selectedDay && timeInputRef.current) {
      const timeValue = timeInputRef.current.value;
      console.log('Adding start time:', selectedDay, timeValue);

      setRouteData(prevData => ({
        ...prevData,
        start_times: {
          ...prevData.start_times,
          [selectedDay]: [...(prevData.start_times[selectedDay] || []), timeValue]
        }
      }));
      timeInputRef.current.value = '';
    } else {
      console.warn('Selected day or time input is not available.');
    }
  };

  const handleLocationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const selectedCenter = collectionCenters.find(center => center.value === selectedValue);
    if (selectedCenter && !selectedLocationIds.some(location => location.id === selectedCenter.value)) {
      setSelectedLocationIds(prev => [...prev, { id: selectedCenter.value, name: selectedCenter.label }]);
    }
  };

  const sections = [
    {
      title: 'Route Details',
      fields: [
        { name: 'name', label: 'Route Name', type: 'text', required: true },
        {
          name: 'location_ids',
          label: 'Collection Centers',
          type: 'select',
          options: collectionCenters,
          required: true,
          onChange: handleLocationChange,
        },
        { name: 'distance', label: 'Distance (km)', type: 'number', required: true },
        {
          name: 'day_of_week',
          label: 'Select Day of the Week',
          type: 'select',
          ref: dayInputRef,
          options: ['select day of the week', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => ({
            value: day,
            label: day.charAt(0).toUpperCase() + day.slice(1)
          })),
        },
        {
          name: 'start_time_input',
          label: 'Start Time',
          type: 'time',
          ref: timeInputRef,
        },
      ]
    }
  ];

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="container mx-auto px-6 py-8">
            <h1 className="text-3xl font-semibold text-gray-800 mb-6">Add New Route</h1>
            <div className="">
              <GenericForm
                ref={formRef}
                initialValues={routeData}
                onSubmit={handleSubmit}
                method="POST"
                sections={sections}
                className="p-6"
              />
            </div>
            <div className="mt-8 flex justify-end">
              <Button
                type="button"
                className="px-2 py-2 mx-1 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
                onClick={addStartTime}
              >
                Add Start Times
              </Button>
              <Button
                type="button"
                className="px-2 py-2 mx-1 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.submit();
                  } else {
                    console.error("Form ref is null");
                  }
                }}
              >
                Save Route
              </Button>
              <Button
                type="button"
                className="px-2 py-2 mx-1 bg-green-600 text-white rounded-md hover:bg-red-700 transition duration-300"
                onClick={() => console.log(routeData)}
              >
                Cancel
              </Button>
            </div>
            {/* Display added start times */}
            <div className="mt-4">
              <h2 className="text-xl font-semibold">Added Start Times:</h2>
              <ul>
                {Object.entries(routeData.start_times).map(([day, times]) => (
                  <li key={day} className="flex justify-between items-center">
                    <span>
                      {day.charAt(0).toUpperCase() + day.slice(1)}: {(times as string[]).join(', ')}
                    </span>
                    <Button
                      type="button"
                      className="ml-2 text-red-600"
                      onClick={() => {
                        // Handle removing start time
                      }}
                    >
                      Remove
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
            {/* Display selected location IDs and names only if there are selected locations */}
            {selectedLocationIds.length > 0 && (
              <div className="mt-4">
                <h2 className="text-xl font-semibold">Selected Collection Centers:</h2>
                <ul>
                  {selectedLocationIds.map(({ id, name }) => (
                    <li key={id} className="flex justify-between items-center">
                      <span>{name} (ID: {id})</span>
                      <Button
                        type="button"
                        className="ml-2 text-red-600"
                        onClick={() => {
                          setSelectedLocationIds(prev => prev.filter(location => location.id !== id));
                        }}
                      >
                        Remove
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
