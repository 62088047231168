import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@/components/atoms/Card';
import userService from '@/api/services/user.service'; // Import the UserService
import { User } from '@/models/user.types'; // Import the User type
import GenericTable from '@/components/atoms/GenericTable';
import { useAuth } from '@/contexts/AuthContext';

interface UsersTableProps {
  selectedMonth: string;
  status: string;
  onMonthChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  monthOptions: Array<{ value: string; label: string }>;
  statusOptions: Array<{ value: string; label: string }>;
  className?: string;
  'data-testid'?: string;
}

export const UsersTable: React.FC<UsersTableProps> = ({
  selectedMonth,
  status,
  onMonthChange,
  onStatusChange,
  monthOptions,
  statusOptions,
  className,
  'data-testid': testId = 'centres-table',
  ...props
}) => {
  const [users, setUsers] = useState<User[]>([]); // State to hold user roles
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const populate = ''; // Specify the fields to populate
        const filter = ''; // Specify the filter
        const orgId = user?.org_id
        const roles = await userService.fetchUsers(orgId, populate, filter)
        setUsers(roles);
      } catch (err) {
        setError('Failed to fetch user roles');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) return <div>Loading user roles...</div>;
  if (error) return <div>{error}</div>;

  const columns = [
    { header: 'Username', accessor: 'username', sortable: true },
    { header: 'First Name', accessor: 'first_name', sortable: true },
    { header: 'Last Name', accessor: 'last_name', sortable: true },
    { header: 'Email', accessor: 'email', sortable: true },
    {
      header: 'Phone',
      accessor: 'contact_info.phone',
      sortable: true,
      render: (item) => (
        <div className="flex items-center">
          {item.contact_info.phone}
        </div>
      ),
    },
    {
      header: 'Address',
      accessor: 'contact_info.address',
      sortable: true,
      render: (item) => (
        <div className="flex items-center">
          {item.contact_info.address}
        </div>
      ),
    },
  ];

  const handleActionClick = (actionType: string, item: any) => {
    console.log(`Action ${actionType} clicked for:`, item);
    // Implement your action logic here
  };

  const renderRowActions = (item: any) => {
    const actions = [
      { type: 'button', label: 'Edit', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-4 mx-1 rounded' },
      // { type: 'Call', label: 'Call', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-1 mx-1 rounded' },
      // { type: 'View Progress', label: 'View Progress', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-1 mx-1 rounded' },
    ];

    if (actions.length === 0) {
      return null;
    }

    return (
      <>
        {actions.map(action => (
          <button
            key={action.type}
            onClick={() => handleActionClick(action.type, item)}
            className={action.className} // Apply Tailwind CSS classes
          >
            {action.label}
          </button>
        ))}
      </>
    );
  };

  return (
    <Card className={className} data-testid={testId} {...props}>
      <CardContent className="p-4">
        <GenericTable
          data={users} // Use fetched user roles data
          columns={columns}
          testId={`${testId}-table`}
          renderRowActions={renderRowActions}
        />
      </CardContent>
    </Card>
  );
};
