import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@/components/atoms/Card';
import roleService from '@/api/services/role.service'; // Import the RoleService
import { Role } from '@/models/role.types'; // Import the Role type
import GenericTable from '@/components/atoms/GenericTable';
import { useAuth } from '@/contexts/AuthContext';

interface RolesTableProps {
  selectedMonth: string;
  status: string;
  onMonthChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  monthOptions: Array<{ value: string; label: string }>;
  statusOptions: Array<{ value: string; label: string }>;
  className?: string;
  'data-testid'?: string;
}

export const RolesTable: React.FC<RolesTableProps> = ({
  selectedMonth,
  status,
  onMonthChange,
  onStatusChange,
  monthOptions,
  statusOptions,
  className,
  'data-testid': testId = 'centres-table',
  ...props
}) => {
  const [roles, setRoles] = useState<Role[]>([]); // State to hold role roles
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const populate = ''; // Specify the fields to populate
        const filter = ''; // Specify the filter
        const orgId = user?.org_id
        const roles = await roleService.fetchRoles(orgId, populate, filter)
        setRoles(roles);
      } catch (err) {
        setError('Failed to fetch roles');
      } finally {
        setLoading(false);
      }
    };

    fetchRoles();
  }, []);

  if (loading) return <div>Loading role roles...</div>;
  if (error) return <div>{error}</div>;

  const columns = [
    { header: 'Name', accessor: 'name', sortable: true },
    {
      header: 'Permissions',
      accessor: 'permissions',
      sortable: true,
      render: (item) => item.permissions ? item.permissions.length : 'N/A'
    },
    // {
    //   header: 'Address',
    //   accessor: 'contact_info.address',
    //   sortable: true,
    //   render: (item) => (
    //     <div className="flex items-center">
    //       {item.contact_info.address}
    //     </div>
    //   ),
    // },
  ];

  const handleActionClick = (actionType: string, item: any) => {
    console.log(`Action ${actionType} clicked for:`, item);
    // Implement your action logic here
  };

  const renderRowActions = (item: any) => {
    const actions = [
      { type: 'button', label: 'Edit', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-4 mx-1 rounded' },
      // { type: 'Call', label: 'Call', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-1 mx-1 rounded' },
      // { type: 'View Progress', label: 'View Progress', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-1 mx-1 rounded' },
    ];

    if (actions.length === 0) {
      return null;
    }

    return (
      <>
        {actions.map(action => (
          <button
            key={action.type}
            onClick={() => handleActionClick(action.type, item)}
            className={action.className} // Apply Tailwind CSS classes
          >
            {action.label}
          </button>
        ))}
      </>
    );
  };

  return (
    <Card className={className} data-testid={testId} {...props}>
      <CardContent className="p-4">
        <GenericTable
          data={roles} // Use fetched role roles data
          columns={columns}
          testId={`${testId}-table`}
          renderRowActions={renderRowActions}
        />
      </CardContent>
    </Card>
  );
};
