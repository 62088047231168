// import React from 'react';
// import { Card, CardContent } from "@/components/atoms/Card";
// import { FilterControls } from '../FilterControls/FilterControls';

// interface CollectionChartProps {
//   selectedMonth: string;
//   status: string;
//   onMonthChange: (value: string) => void;
//   onStatusChange: (value: string) => void;
//   monthOptions: Array<{ value: string; label: string }>;
//   statusOptions: Array<{ value: string; label: string }>;
// }

// export const CollectionChart = ({
//   selectedMonth,
//   status,
//   onMonthChange,
//   onStatusChange,
//   monthOptions,
//   statusOptions
// }: CollectionChartProps) => (
//   <Card className="mb-6">
//     <CardContent className="p-4">
//       <div className="flex justify-between items-center mb-4">
//         <h2 className="text-lg font-semibold">Collection In Litres</h2>
//         <FilterControls
//           monthOptions={monthOptions}
//           statusOptions={statusOptions}
//           selectedMonth={selectedMonth}
//           status={status}
//           onMonthChange={onMonthChange}
//           onStatusChange={onStatusChange}
//         />
//       </div>
//       {/* Chart placeholder */}
//       <div className="h-[400px] bg-gray-50 rounded-lg flex items-center justify-center">
//         <div className="text-center text-gray-500">
//           <p>Chart will be implemented here</p>
//           <p className="text-sm">Using a chart library of your choice</p>
//         </div>
//       </div>
//     </CardContent>
//   </Card>
// );

// src/components/molecules/CollectionChart.tsx
import React from 'react';
import { Card, CardContent } from "@/components/atoms/Card";
import { FilterControls } from '../FilterControls/FilterControls';

interface CollectionChartProps {
  selectedMonth: string;
  status: string;
  onMonthChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  monthOptions: Array<{ value: string; label: string }>;
  statusOptions: Array<{ value: string; label: string }>;
  className?: string;
  'data-testid'?: string;
}

export const CollectionChart: React.FC<CollectionChartProps> = ({
  selectedMonth,
  status,
  onMonthChange,
  onStatusChange,
  monthOptions,
  statusOptions,
  className,
  'data-testid': testId = 'collection-chart',
  ...props
}) => (
  <Card className={`mb-6 ${className || ''}`} data-testid={testId} {...props}>
    <CardContent className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold" data-testid={`${testId}-title`}>
          Collection In Litres
        </h2>
        <FilterControls
          monthOptions={monthOptions}
          statusOptions={statusOptions}
          selectedMonth={selectedMonth}
          status={status}
          onMonthChange={onMonthChange}
          onStatusChange={onStatusChange}
          data-testid={`${testId}-filters`}
        />
      </div>
      
      {/* Selected values display - helps with testing */}
      <div className="mb-4 text-sm text-gray-600">
        <p data-testid={`${testId}-selected-month`}>Selected Month: {selectedMonth}</p>
        <p data-testid={`${testId}-selected-status`}>Selected Status: {status}</p>
      </div>

      {/* Chart placeholder */}
      <div 
        className="h-[400px] bg-gray-50 rounded-lg flex items-center justify-center"
        data-testid={`${testId}-placeholder`}
      >
        <div className="text-center text-gray-500">
          <p>Chart will be implemented here</p>
          <p className="text-sm">Using a chart library of your choice</p>
        </div>
      </div>
    </CardContent>
  </Card>
);