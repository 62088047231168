import React from 'react';
import { InputProps } from './Input.types';
import { inputStyles } from './Input.styles';

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ error, helperText, className = '', icon, ...props }, ref) => (
    <div className="w-full">
      <div className="relative">
        <input
          ref={ref}
          className={`${inputStyles.base} ${error ? inputStyles.error : inputStyles.default} ${className}`}
          {...props}
        />
        {icon && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            {icon}
          </div>
        )}
      </div>
      {helperText && (
        <p className={`mt-1 text-sm ${error ? 'text-red-500' : 'text-gray-500'}`}>
          {helperText}
        </p>
      )}
    </div>
  )
);

Input.displayName = 'Input';
