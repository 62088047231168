// src/api/services/location.service.ts
import { Location, LocationCreate, LocationUpdate } from '../../models/location.types';
import { BaseService } from './base.service';
import api from '../axios';

class LocationService extends BaseService<Location, LocationCreate, LocationUpdate> {
  constructor() {
    super('/locations');
  }

  // Updated method to fetch location with dynamic populate and filter parameters
  async fetchLocations(orgId: string, populate: string, filter: string) {
    const response = await api.get<Location[]>(`${this.endpoint}?populate=${populate}&org_id=${orgId}&filter=${filter}`);
    return response.data;
  }
}

export default new LocationService();
