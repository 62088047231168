import React, { useRef, useEffect, useState } from 'react';
import { Button } from '@/components/atoms/Button';
import { Loader } from '@googlemaps/js-api-loader';

interface MapModalProps {
  isOpen: boolean;
  onClose: () => void;
  onLocationSelect: (lat: number, lng: number, address: string) => void;
}

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  version: "weekly",
  libraries: ["places"],
  authReferrerPolicy: "origin"
});

console.log('Environment:', import.meta.env.MODE);
console.log('API Key:', import.meta.env.VITE_GOOGLE_MAPS_API_KEY?.substring(0, 8) + '...');

let isLoaded = false;

const MapModal: React.FC<MapModalProps> = ({ isOpen, onClose, onLocationSelect }) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);
  const searchBoxRef = useRef<google.maps.places.Autocomplete | null>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [isMounted, setIsMounted] = useState(false);

  // Handle mounting
  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    }
    return () => setIsMounted(false);
  }, [isOpen]);

  // Initialize map and search box
  useEffect(() => {
    if (!isOpen || !isMounted || !mapContainerRef.current || !searchInputRef.current) return;

    let timeoutId: NodeJS.Timeout;

    const initMap = async () => {
      try {
        if (!isLoaded) {
          await loader.load();
          isLoaded = true;
        }

        // Delay map initialization slightly to ensure DOM is ready
        timeoutId = setTimeout(() => {
          if (!mapContainerRef.current) return;

          // Initialize map
          const map = new google.maps.Map(mapContainerRef.current, {
            center: { lat: -1.2921, lng: 36.8219 }, // Default to Nairobi
            zoom: 12,
            mapTypeControl: true,
            fullscreenControl: true,
          });
          mapRef.current = map;

          // Initialize Autocomplete
          if (searchInputRef.current) {
            const autocomplete = new google.maps.places.Autocomplete(searchInputRef.current, {
              types: ['geocode', 'establishment'],
              fields: ['formatted_address', 'geometry', 'name']
            });

            autocomplete.bindTo('bounds', map);
            searchBoxRef.current = autocomplete;

            // Handle place selection
            autocomplete.addListener('place_changed', () => {
              const place = autocomplete.getPlace();

              if (!place.geometry || !place.geometry.location) {
                console.log("No location found for this place");
                return;
              }

              // Update map view
              map.setCenter(place.geometry.location);
              map.setZoom(17);

              // Update or create marker
              if (markerRef.current) {
                markerRef.current.setPosition(place.geometry.location);
              } else {
                markerRef.current = new google.maps.Marker({
                  map,
                  position: place.geometry.location,
                  draggable: true,
                });
              }

              // Handle marker drag
              markerRef.current.addListener('dragend', () => {
                const position = markerRef.current?.getPosition();
                if (position) {
                  updateLocationDetails(position);
                }
              });

              // Update form with location details
              onLocationSelect(
                place.geometry.location.lat(),
                place.geometry.location.lng(),
                place.formatted_address || place.name || ''
              );
            });

            // Add click listener to map
            map.addListener('click', (e: google.maps.MapMouseEvent) => {
              const clickedLocation = e.latLng;
              if (!clickedLocation) return;

              // Update or create marker
              if (markerRef.current) {
                markerRef.current.setPosition(clickedLocation);
              } else {
                markerRef.current = new google.maps.Marker({
                  map,
                  position: clickedLocation,
                  draggable: true,
                });

                // Handle marker drag
                markerRef.current.addListener('dragend', () => {
                  const position = markerRef.current?.getPosition();
                  if (position) {
                    updateLocationDetails(position);
                  }
                });
              }

              updateLocationDetails(clickedLocation);
            });
          }
        }, 100);
      } catch (error) {
        console.error('Error initializing map:', error);
      }
    };

    initMap();

    return () => {
      clearTimeout(timeoutId);
      if (markerRef.current) {
        markerRef.current.setMap(null);
        markerRef.current = null;
      }
      mapRef.current = null;
      searchBoxRef.current = null;
    };
  }, [isOpen, isMounted, onLocationSelect]);

  // Helper function to get address from coordinates
  const updateLocationDetails = async (location: google.maps.LatLng) => {
    try {
      const geocoder = new google.maps.Geocoder();
      const result = await geocoder.geocode({ location });

      if (result.results[0]) {
        onLocationSelect(
          location.lat(),
          location.lng(),
          result.results[0].formatted_address
        );

        // Update search input with new address
        if (searchInputRef.current) {
          searchInputRef.current.value = result.results[0].formatted_address;
        }
      }
    } catch (error) {
      console.error('Geocoding error:', error);
    }
  };

  // Don't render anything if modal is not open
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-lg w-[800px]">
        <div className="mb-4">
          <input
            ref={searchInputRef}
            type="text"
            placeholder="Search for a location..."
            className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-green-500 focus:border-green-500"
          />
        </div>
        <div
          ref={mapContainerRef}
          className="w-full h-[400px] rounded-lg border border-gray-300"
          style={{
            position: 'relative',
            visibility: isMounted ? 'visible' : 'hidden'
          }}
        />
        <div className="mt-4 flex justify-end gap-2">
          <Button
            type="button"
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MapModal;
