'use client'

import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from '@/components/organisms/Sidebar';
import GenericForm from '@/components/atoms/GenericForm';
import { Button } from '@/components/atoms/Button';
import { useAuth } from '@/contexts/AuthContext';
import roleService from '@/api/services/role.service';
import MapModal from '@/components/organisms/MapModal';
import locationService from '@/api/services/location.service';
import { useNavigate } from 'react-router-dom';

// Add interface for QA Agent
interface QAAgent {
  id: string;
  name: string;
}

// Update centerData interface and initial state
interface CenterData {
  centerName: string;
  address: string;
  latitude: string;
  longitude: string;
  contactPerson: string;
  phoneNumber: string;
  operatingHours: string;
  assignedQAAgent: string;
  status: boolean;
  notes: string;
  capacity: number;
}

export default function AddCollectionCenter() {
  const { user } = useAuth();
  const [qaAgents, setQAAgents] = useState<QAAgent[]>([]);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  const [centerData, setCenterData] = useState<CenterData>({
    centerName: '',
    address: '',        // Will be updated from map selection
    latitude: '',       // Will be updated from map selection
    longitude: '',      // Will be updated from map selection
    contactPerson: '',
    phoneNumber: '',
    operatingHours: '',
    assignedQAAgent: '',
    status: true,
    notes: '',
    capacity: 0
  });

  // Add useEffect to fetch QA/Collector roles
  useEffect(() => {
    const fetchQAAgents = async () => {
      if (!user?.org_id) return;

      try {
        const filter = 'name:QA/Collector';
        const roles = await roleService.fetchRoles(user.org_id, '', filter);
        const formattedAgents = roles.map(role => ({
          id: role.id,
          name: role.name
        }));
        setQAAgents(formattedAgents);
      } catch (error) {
        console.error('Error fetching QA agents:', error);
      }
    };

    fetchQAAgents();
  }, [user?.org_id]);

  const handleSubmit = async (values: Record<string, any>, method: string) => {
    console.log('Form submitted with values:', values);
    try {
      if (!user?.org_id) {
        throw new Error('Organization ID is required');
      }

      const locationData = {
        name: values.centerName,
        address: values.address,
        type: 'collection_center',
        organization_id: user.org_id,
        coordinates: {
          latitude: parseFloat(values.latitude),
          longitude: parseFloat(values.longitude)
        },
        capacity: values.capacity || 0,
        is_active: values.status
      };

      console.log('Sending location data:', locationData);
      const response = await locationService.create(locationData);
      console.log('Collection center created:', response);

      navigate('/logistics-management/collection-centers-and-qa-agents');

    } catch (error) {
      console.error('Error creating collection center:', error);
      // Handle error (show error notification, etc.)
    }
  };

  const handleLocationSelect = (lat: number, lng: number, address: string) => {
    setCenterData(prev => ({
      ...prev,
      latitude: lat.toString(),
      longitude: lng.toString(),
      address: address
    }));
    setIsMapOpen(false);
  };

  const sections = [
    {
      fields: [
        {
          name: 'centerName',
          label: 'Center Name',
          type: 'text',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md'
        },
      ]
    },
    {
      fields: [
        {
          name: 'address',
          label: 'Address',
          type: 'textarea',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md min-h-[100px]',
          disabled: true,
          value: centerData.address
        },
      ]
    },
    {
      fields: [
        {
          name: 'latitude',
          label: 'Latitude',
          type: 'text',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md',
          disabled: true,
          value: centerData.latitude
        },
        {
          name: 'longitude',
          label: 'Longitude',
          type: 'text',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md',
          disabled: true,
          value: centerData.longitude
        },
        {
          name: 'openMap',
          type: 'custom',
          className: 'flex items-end',
          render: () => (
            <div className="flex flex-col justify-end h-full">
              <Button
                type="button"
                onClick={() => setIsMapOpen(true)}
                className="px-4 py-2 bg-green-700 text-white rounded-md hover:bg-green-800 w-full"
              >
                {centerData.address ? 'Change Location' : 'Select Location'}
              </Button>
            </div>
          )
        }
      ]
    },
    {
      fields: [
        {
          name: 'contactPerson',
          label: 'Contact Person',
          type: 'text',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md'
        },
        {
          name: 'phoneNumber',
          label: 'Phone Number',
          type: 'text',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md'
        },
      ]
    },
    {
      fields: [
        {
          name: 'operatingHours',
          label: 'Operating Hours',
          type: 'time',
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md'
        },
      ]
    },
    {
      fields: [
        {
          name: 'assignedQAAgent',
          label: 'Assigned QA Agent',
          type: 'select',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md',
          options: [
            { value: '', label: 'Select QA Agent' },
            ...qaAgents.map(agent => ({
              value: agent.id,
              label: agent.name
            }))
          ]
        },
      ]
    },
    {
      fields: [
        {
          name: 'status',
          label: 'Status',
          type: 'checkbox',
          required: true,
          className: 'h-5 w-5 rounded border-gray-300 text-green-700 focus:ring-green-700',
        },
      ]
    },
    {
      fields: [
        {
          name: 'notes',
          label: 'Notes',
          type: 'textarea',
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md min-h-[100px]'
        }
      ]
    },
    {
      fields: [
        {
          name: 'capacity',
          label: 'Capacity',
          type: 'number',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md'
        },
      ]
    }
  ];

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="container mx-auto px-6 py-8">
            <div className="bg-white shadow-md rounded-lg mb-6 p-6">
              <h1 className="text-2xl font-semibold text-gray-800">Add Collection Center</h1>
            </div>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <GenericForm
                ref={formRef}
                initialValues={centerData}
                onSubmit={handleSubmit}
                method="POST"
                sections={sections}
                className="p-6"
              />
              <div className="flex justify-between px-6 py-4 bg-gray-50 border-t border-gray-200">
                <div className="space-x-4">
                  <Button
                    type="button"
                    className="px-6 py-2 bg-green-700 text-white rounded-md hover:bg-green-800"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    className="px-2 py-2 mx-1 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
                    onClick={() => {
                      if (formRef.current) {
                        formRef.current.submit();
                      } else {
                        console.error("Form ref is null");
                      }
                    }}
                  >
                    Save Collection Center
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </main>
        <MapModal
          isOpen={isMapOpen}
          onClose={() => setIsMapOpen(false)}
          onLocationSelect={handleLocationSelect}
          defaultCenter={{ lat: -1.2921, lng: 36.8219 }}
          defaultZoom={12}
        />
      </div>
    </div>
  );
}
