import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@/components/atoms/Card';
import routeService from '@/api/services/route.service'; // Import the RouteService
import GenericTable from '@/components/atoms/GenericTable';
import { useAuth } from '@/contexts/AuthContext';

interface RoutesManagementTableProps {
  selectedMonth: string;
  status: string;
  onMonthChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  monthOptions: Array<{ value: string; label: string }>;
  statusOptions: Array<{ value: string; label: string }>;
  className?: string;
  'data-testid'?: string;
}

export const RoutesManagementTable: React.FC<RoutesManagementTableProps> = ({
  selectedMonth,
  status,
  onMonthChange,
  onStatusChange,
  monthOptions,
  statusOptions,
  className,
  'data-testid': testId = 'centres-table',
  ...props
}) => {
  const [routes, setRoutes] = useState<any[]>([]); // State to hold routes data
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    const fetchRoutes = async () => {
      try {
        const populate = 'locations'; // Specify the fields to populate
        const filter = ''; // Specify the filter
        const orgId = user?.org_id
        const fetchedRoutes = await routeService.fetchRoutes(orgId, populate, filter); // Fetch routes from the service
        setRoutes(fetchedRoutes);
      } catch (err) {
        setError('Failed to fetch routes');
      } finally {
        setLoading(false);
      }
    };

    fetchRoutes();
  }, []);


  if (loading) return <div>Loading payments...</div>;
  if (error) return <div>{error}</div>;

  const columns = [
    { header: 'Route Name', accessor: 'name', sortable: true },
    { header: 'Assigned Driver', accessor: 'protein', sortable: true },
    {
      header: 'Collection Centers',
      accessor: 'locations',
      sortable: true,
      render: (item) => (
        <div className="flex items-center">
          {item.locations.length}
        </div>
      ),
    },
    { header: 'Start Time', accessor: 'scc', sortable: true },
    { header: 'Status', accessor: 'location', sortable: true },
  ];

  const handleActionClick = (actionType: string, item: any) => {
    console.log(`Action ${actionType} clicked for:`, item);
    // Implement your action logic here
  };

  const renderRowActions = (item: any) => {
    const actions = [
      { type: 'Edit', label: 'Edit', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-4 mx-2 rounded' },
      { type: 'Assign', label: 'Assign', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-4 mx-2 rounded' },
      { type: 'Map', label: 'Map', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-2 mx-1 rounded' },
    ];

    if (actions.length === 0) {
      return null;
    }

    return (
      <>
        {actions.map(action => (
          <button
            key={action.type}
            onClick={() => handleActionClick(action.type, item)}
            className={action.className} // Apply Tailwind CSS classes
          >
            {action.label}
          </button>
        ))}
      </>
    );
  };

  return (
    <Card className={className} data-testid={testId} {...props}>
      <CardContent className="p-4">
        <GenericTable
          data={routes} // Use fetched user roles data
          columns={columns}
          testId={`${testId}-table`}
          renderRowActions={renderRowActions}
        />
      </CardContent>
    </Card>
  );
};
