// import React from 'react';
// import { Select } from '@/components/atoms/Select';
// import { FilterControlsProps } from './FilterControls.types';

// export const FilterControls: React.FC<FilterControlsProps> = ({
//   monthOptions,
//   statusOptions,
//   selectedMonth,
//   status,
//   onMonthChange,
//   onStatusChange
// }) => {
//   return (
//     <div className="flex gap-4">
//       <Select
//         options={monthOptions}
//         value={selectedMonth}
//         onChange={(e) => onMonthChange(e.target.value)}
//         className="w-40"
//       />
//       <Select
//         options={statusOptions}
//         value={status}
//         onChange={(e) => onStatusChange(e.target.value)}
//         className="w-40"
//       />
//     </div>
//   );
// };

import React from 'react';
import { Select } from '@/components/atoms/Select';
import { Input } from '@/components/atoms/Input';
import { FilterControlsProps } from './FilterControls.types';

export const FilterControls: React.FC<FilterControlsProps> = ({
  monthOptions,
  statusOptions,
  selectedMonth,
  status,
  onMonthChange,
  onStatusChange,
  searchTerm,
  onSearchChange,
  selectedDate,
  onDateChange,
}) => {
  return (
    <div className="flex gap-4">
      <Input
        type="search"
        placeholder="Search by Route Name or Driver"
        value={searchTerm}
        onChange={onSearchChange}
        icon={
          <svg className="w-5 h-5 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        }
      />
      <Input
        type="date"
        value={selectedDate}
        onChange={onDateChange}
      />
      <Select
        options={monthOptions}
        value={selectedMonth}
        onChange={(e) => onMonthChange(e.target.value)}
        placeholder="Select Month"
      />
      <Select
        options={statusOptions}
        value={status}
        onChange={(e) => onStatusChange(e.target.value)}
        placeholder="Select Status"
      />
    </div>
  );
};
