// src/contexts/AuthContext.tsx
import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import authService from '@/api/services/auth.service';
import type { User } from '@/models/auth.types';
import { LoadingScreen } from '@/components/atoms/LoadingScreen';

interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  isLoading: boolean;  // Add isLoading to context
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuth = useCallback(async () => {
    try {
      const userData = await authService.getCurrentUser();
      if (userData?.id && userData?.email) {
        setUser({
          id: userData.id,
          email: userData.email,
          first_name: userData.first_name || '',
          last_name: userData.last_name || '',
          avatarUrl: userData.avatarUrl || '',
          username: userData.username || '',
          org_id: userData.organization_ids[0] || '',
        });
        setIsAuthenticated(true);
      } else {
        throw new Error('Invalid user data');
      }
    } catch (error) {
      setUser(null);
      setIsAuthenticated(false);
    } finally {
      // Small delay to prevent flash
      setTimeout(() => setIsLoading(false), 100);
    }
  }, []);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const login = useCallback(async (email: string, password: string) => {
    try {
      const loginResponse = await authService.login(email, password);

      if (!loginResponse.access_token) {
        throw new Error('No access token received');
      }

      const userData = await authService.getCurrentUser();

      if (!userData.id || !userData.email) {
        throw new Error('Missing required user data');
      }

      setUser({
        id: userData.id,
        email: userData.email,
        first_name: userData.first_name || '',
        last_name: userData.last_name || '',
        avatarUrl: userData.avatarUrl || '',
        username: userData.username || '',
        org_id: userData.organization_ids[0] || '',
      });
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
      setUser(null);
      throw error instanceof Error ? error : new Error('Authentication failed');
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      await authService.logout();
    } catch (error) {
      const transformedError = error instanceof Error
        ? error
        : new Error(error?.message || 'Logout failed');

      setUser(null);
      setIsAuthenticated(false);

      throw transformedError;
    }

    setUser(null);
    setIsAuthenticated(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isLoading,
        login,
        logout
      }}
    >
      {children}
      {isLoading && <LoadingScreen />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
